<template>
  <Portal
    :menus="portal"
    :resourceType="resourceType"
    :resourceServer="parameter.resourceServer"
    :resourceId="parameter.resourceId"
    :endpoint="parameter.endpoint"
    :firewall="firewall"
  ></Portal>
</template>

<script>
export default {
  props: {
    resourceType: String,
    parameter: Object,
  },
  components: {
    Portal: () => import("cms_infrastructure_web/src/sdk/Admin/Portal/Portal.vue"),
  },
  data: function () {
    return {
      portal: {
        Provider: [
          {
            name: "Provider",
            content: "components/Provider/Portal-Provider.vue",
          },
        ],
        User: [
          {
            name: "Client",
            content: "components/Client/Portal-Client.vue",
          },
          {
            name: "Group",
            content: "components/Group/Portal-Group.vue",
          },
        ],
        Message: [
          {
            name: "Template",
            content: "components/Template/Portal-Template.vue",
          },
          {
            name: "Variable",
            content: "components/Variable/Portal-Variable.vue",
          },
        ],
      },
      firewall: [
        { label: "All", value: "Default" },
        { label: "Backend", value: "Backend" },
        { label: "Frontend", value: "Frontend" },
        { label: "Security", value: "Security" },
        { label: "Debug", value: "Debug" },
        { label: "Analysis", value: "Analysis" },
        { label: "Notify", value: "Notify" },
      ],
    };
  },
};
</script>
